<template>
  <div>
    <kovi-page-title :text="$t('maintenance.titles.scheduledInspectionView')" />

    <b-row>
      <b-col sm="12">
        <scheduled-inspection-list />
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import KoviPageTitle from '@components/shared/kovi-page-title';
  import ScheduledInspectionList from '@components/schedule/scheduled-inspection-list/ScheduledInspectionList';

  export default {
    name: 'ScheduleInspection',

    components: {
      KoviPageTitle,
      ScheduledInspectionList,
    },
  };

</script>
